<template>
  <div class="box-style">
    <img src="../assets/login-bg.png" class="bg-style" alt="" />
    <div class="top">
      <img src="../assets/logo.png" class="logo-style" alt="" />
    </div>
    <van-form @submit="onSubmit" class="form-style">
      <div style="background: #FFFFFF;padding: 15px 0;">
        <van-field v-model="username" name="username" label="用户名" placeholder="用户名"
        :rules="[{ required: true, message: '请填写用户名' }]" />
        <van-field v-model="password" type="password" name="password" label="密码" placeholder="密码"
        :rules="[{ required: true, message: '请填写密码' }]" />
      </div>
      <!-- <van-field v-model="authcode" name="authcode" label="验证码" placeholder="验证码"
        :rules="[{ required: true, message: '请填写验证码' }]" /> -->
      <!-- <div class="img-style">
        <img :src="codeUrl" alt="" @click="getAutoCode" />
      </div> -->
      <div style="margin: 16px">
        <van-button round block type="info" native-type="submit">登录</van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import api from "@/api/api.js";
import {
    Toast
} from 'vant';
export default {
  data () {
    return {
      username: "",
      password: "",
      authcode: "",
      codeKey: "",
      codeUrl: "",
      type: 0, //0签单  1工程  2深化
    };
  },
  methods: {
    //登录
    async onSubmit (values) {
      let obj = {
        key: this.codeKey,
        code: values.authcode,
        password: values.password,
        username: values.username,
      };
      let formData = new FormData();
      formData.append('key', this.codeKey);
      formData.append('code', values.authcode);
      formData.append('password', values.password);
      formData.append('username', values.username);

      let toast1 ;
      if(values.tryAutoLogin){
        formData.append('tryAutoLogin', true);
        toast1 = Toast.loading({
            duration: 0, // 持续展示 toast
            forbidClick: true,
            message: '登录中...'
        });
      }
      try {
        let res = await api.login(formData)
        if (res.code == 200) {
            if(toast1)toast1.clear()
            sessionStorage.setItem('TOKEN', res.data.userId)
            res.data.btn = res.data.btn ? JSON.parse(res.data.btn) : []
            sessionStorage.setItem('USERINFO', JSON.stringify(res.data))
            //任务看板 清除缓存 
            localStorage.removeItem("USER_ROLE");
            localStorage.removeItem("INDEX_TAB_ACTIVE");
            if(this.$route.query.historyRouterName){
                this.$router.replace({ 
                    name: this.$route.query.historyRouterName,
                    query: { ...this.$route.query,alreadyLogin: true },
                    params:{ ...this.$route.params }
                })
                return
            }
            if (this.type == 0) this.$router.replace({ name: "clientSignBillListName" })//跳转客户管理-签单
            if (this.type == 5) this.$router.replace({ name: "clientTelephoneOperatorName" })//跳转客户管理-话务
            if (this.type == 1) this.$router.replace({ name: "engineeringName" })//跳转工程
            if (this.type == 2) this.$router.replace({ name: "deepenName" })//跳转深化
            if (this.type == 3) this.$router.replace({ name: "storageListName" })//跳转仓储
            if (this.type == 7) this.$router.replace({ name: "TaskManagement" }); //跳转任务看板
            if (this.type == 6) {
                let list = await api.getBoardAuthority(res.data.userId)
                // this.$router.replace({ name: "dataBoard" })//跳转数据看板
                // this.$router.replace({ name: "president" })//跳转科技看板
                if(list.code == 200){
                    this.$router.replace({ name: list.data })
                }
            }
            if (this.type == 4) {
                if (this.$route.query.messageType == '3' || (this.$route.query.messageType == '33' && ['41','48', '49', '54'].indexOf(this.$route.query.businessType)>-1)) {
                    //审批消息
                    this.$router.replace({
                        name: "messageName",
                        query: {
                            bussinessId: this.$route.query.bussinessId,
                            procInstanceId: this.$route.query.procInstanceId,
                            approvalNoticeType: this.$route.query.approvalNoticeType,
                            businessType: this.$route.query.businessType,
                            orderId: this.$route.query.orderId,
                            messageId: this.$route.query.messageId,
                            messageType: this.$route.query.messageType
                        }
                    })
                } else if (this.$route.query.messageType == '4') {
                    let r = await api.getDepart(res.data.userId)
                    if (r.data.deptName?.indexOf('客服') > -1) {
                        // 到店通知跳转客服客户详情
                        this.$router.replace({
                            name: 'clientTDetailsName',
                            params: {
                                id: this.$route.query.consumerId
                            },
                            query: {
                                isDirectRoute: true//true直接跳转false间接跳转（从客户列表跳转）
                            }
                        })
                    } else if (r.data.deptName?.indexOf('话务') > -1) {
                        // 到店通知跳转话务客户详情
                        this.$router.replace({
                            name: 'clientTelephoneDetailsName',
                            params: {
                                id: this.$route.query.consumerId
                            }
                        })
                    }
                } else if (this.$route.query.messageType == '5') {
                    // 分配通知跳转签单客户详情
                    this.$router.replace({
                        name: 'clientDetailsName',
                        params: {
                            id: this.$route.query.consumerId
                        },
                        query: {
                            isDirectRoute: true//true直接跳转false间接跳转（从客户列表跳转）
                        }
                    })
                } else if (this.$route.query.messageType == '6') {
                        // 财务放款
                        if(this.$route.query.documentType == '0'){
                            this.$router.replace({
                                name: 'ApplyFinancialNew',
                                query: {
                                    id: this.$route.query.allocateId,
                                    logId: this.$route.query.logId,
                                    projectId: this.$route.query.projectId,
                                    nodeCode: this.$route.query.nodeCode,
                                }
                            })
                        }else{
                            this.$router.replace({
                                name: 'ApplyFinancial',
                                params: {
                                    id: this.$route.query.allocateId,
                                    logId: this.$route.query.logId
                                }
                            })
                        }
                } else if (this.$route.query.messageType == '7') {
                    // 缴费审核
                    this.$router.replace({
                        name: 'AdvancePaymentCheck',
                        params: {
                            id: this.$route.query.refId,
                            logId: this.$route.query.logId
                        }
                    })
                } else if (this.$route.query.messageType == '8' || this.$route.query.messageType == '9') {
                    // 自理项目确认单通知退货单通知
                    this.$router.replace({
                        name: 'ConfirmationSlip',
                        params: {
                            id: this.$route.query.refId,
                            logId: this.$route.query.logId
                        }
                    })

                } else if (this.$route.query.messageType == '10') {
                    // 自理项目验收分包单通知---上传
                    this.$router.replace({
                        name: 'projectSubcontract',
                        params: {
                            id: this.$route.query.refId,
                            logId: this.$route.query.logId
                        }
                    })
                } else if (this.$route.query.messageType == '11') {
                    // 自理项目分包单通知
                    this.$router.replace({
                        name: 'ResultSubcontract',
                        params: {
                            id: this.$route.query.refId,
                            logId: this.$route.query.logId
                        }
                    })
                } else if (this.$route.query.messageType == '12') {
                    // 上传施工图片
                    this.$router.replace({
                        name: 'NewSubcontract',
                        params: {
                            id: this.$route.query.refId,
                            logId: this.$route.query.logId
                        }
                    })

                } else if (this.$route.query.messageType == '13') {
                    this.$router.replace({
                        name: 'dataBoardMessage',
                        query: {
                            logId: this.$route.query.logId
                        }
                    })
                } else if (this.$route.query.messageType == '14') {
                    // 下单通知
                    this.$router.replace({
                        name: 'PlaceOrder',
                        params: {
                            id: this.$route.query.refId,
                            logId: this.$route.query.logId
                        }
                    })
                } else if (this.$route.query.messageType == '15') {
                    // 安装完毕通知
                    this.$router.replace({
                        name: 'Installation',
                        params: {
                            id: this.$route.query.refId,
                            logId: this.$route.query.logId
                        }
                    })
                } else if (this.$route.query.messageType == '16') {
                    let routerName = this.$route.query.orderNoticeType
                    // 入库通知
                    this.$router.replace({
                        name: routerName == '自理销售' ? 'CreateStoreDetails' : 'CreateStoreDetails1',
                        params: {
                            id: this.$route.query.refId,
                            logId: this.$route.query.logId,
                            messageId: this.$route.query.messageId,
                        }
                    })
                } else if (this.$route.query.messageType == '17') {
                    // 收银通知
                    this.$router.replace({
                        name: 'AdvancePayment',
                        params: {
                            id: this.$route.query.refId,
                            logId: this.$route.query.logId,
                        }
                    })
                } else if (this.$route.query.messageType == '18') {
                    // 供应商订单消息详情
                    let routerName = this.$route.query.orderNoticeType
                    let routerType = this.$route.query.supplierOrderType
                    let productOrderType = this.$route.query.productOrderType  // 1 工程单 2 自理销售
                    if(productOrderType==='1'){
                        // 工程
                        this.$router.replace({
                            name: 'SupplierOrderDetail',
                            params: {
                                id: this.$route.query.refId,
                                logId: this.$route.query.logId,
                                messageId: this.$route.query.messageId,
                            }
                        })
                    }else if(productOrderType==='2'){
                        // 自理销售
                        this.$router.replace({
                            name: 'SupplierOrderDetail1',
                            params: {
                                id: this.$route.query.refId,
                                logId: this.$route.query.logId,
                                messageId: this.$route.query.messageId,
                            }
                        })
                    }else if (routerName == '自理销售') {
                        this.$router.replace({
                                name: 'ConfirmationSlip',
                                params: {
                                    id: this.$route.query.refId,
                                    logId: this.$route.query.logId,
                                    messageId: this.$route.query.messageId,
                                }
                            })
                    } else {
                        // 工程单
                        this.$router.replace({
                                name: 'ConfirmationSlip1',
                                params: {
                                    id: this.$route.query.refId,
                                    logId: this.$route.query.logId,
                                    messageId: this.$route.query.messageId,
                                }
                            })
                    }
                } else if (this.$route.query.messageType == '19') {
                    // 分配产品专员通知
                    this.$router.replace({
                        name: 'DistributionSpecial',
                        params: {
                            ... this.$route.query,
                            id: this.$route.query.refId,
                            logId: this.$route.query.logId,
                        }
                    })
                } else if (this.$route.query.messageType == '20') {
                    // 选材单审批通知
                    this.$router.replace({
                        name: 'SelectionListDetails',
                        params: {
                            ... this.$route.query,
                            id: this.$route.query.refId,
                            logId: this.$route.query.logId,
                        }
                    })
                }  else if (this.$route.query.messageType == '21') {
                    // 辅材申领查看 通知
                    this.$router.replace({
                        name: 'engineeringMaterialsDetailsName',
                        params: {
                            id: this.$route.query.refId,
                            logId: this.$route.query.logId,
                            messageId: this.$route.query.messageId,
                            applyId: this.$route.query.applyId,
                        }
                    })
                } else if (this.$route.query.messageType == '22' || this.$route.query.messageType == '23') {
                    // 入库审核通知
                    this.$router.replace({
                        name: 'SupplierOrderDetail2',
                        params: {
                            id: this.$route.query.refId,
                            logId: this.$route.query.logId,
                            messageId: this.$route.query.messageId,
                        }
                    })
                } else {
                    //手动消息
                    this.$router.replace({
                        name: "messageInfoName",
                        query: {
                            messageId: this.$route.query.messageId,
                            logId: this.$route.query.logId
                        }
                    })
                }
            }//跳转消息

            if (this.type == 21) this.$router.replace({ name: "TdirectorName" })//话务总监看板
            if (this.type == 22) this.$router.replace({ name: "directorOperationsName" })//运营总裁看板
            if (this.type == 23) this.$router.replace({ name: "TaskManagement" })
        } else {
            this.getAutoCode();
        }
      } catch (error) {
        this.getAutoCode();
      }
      
    },
    //获取验证码
    async getAutoCode () {
        return
      let res = await api.getAutoCode();
      this.codeUrl = res.data.codeUrl;
      this.codeKey = res.data.key;
    },
  },
  mounted () { 
    if(localStorage.getItem('username')&&localStorage.getItem('password')){
        this.username = localStorage.getItem('username')
        this.password = localStorage.getItem('password')
        this.onSubmit({username: this.username, password: this.password,tryAutoLogin:true})
    }
  },
  created () {
    this.type = this.$route.query.type;
    this.getAutoCode();
  },

};
</script>

<style scoped lang="scss">
.box-style {
  position: relative;
  box-sizing: border-box;
  background-color: #f7f8fa;
  height: 100vh;
  padding-top: 10vh;
  // background-image: '../assets/login-bg.png';
  background: #2a43b3;
  // background:url('../assets/login-bg.png') no-repeat 4px 5px;
  // background-color: #1fc8db;
  // background-image: linear-gradient(141deg, #2a43b3 0%, #2a43b3 51%, #2a43b3 75%);
}

.img-style {
  width: 100%;
  background-color: #fff;
  position: relative;
  padding: 2px;
}

.form-style {
  border-radius: 10px;
  // z-index: 5;
  // overflow: hidden;
}

.bg-style {
  width: 100%;
  position: absolute;
  // top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // z-index: 1;
}

.top {
  .logo-style {
    width: 100px;
    height: 100px;
  }
}
</style>
