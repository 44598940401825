<template>
    <div class="messageInfo-box">
        <headerNav title="消息详情" />
        <div class="empty-box" v-if="msgInfo?.isDel == 1">
            <van-image class="empty" :src="require('@/assets/msgEmpty.png')" />
            <p>该消息已被发布者删除</p>
        </div>
        <div v-else>
            <div class="top">
                <div class="title">
                    {{ msgInfo.messageTitle }}
                </div>
                <div class="annotation">
                    <span class="text">{{ msgInfo.sendUser }}</span>
                    <span class="time">{{ msgInfo.sendDate }}</span>
                </div>
            </div>
            <div class="html-content" v-html="htmlContent">

            </div>
        </div>
    </div>
</template>
<script>
import api from '@/api/api.js'
import {
    ImagePreview
} from 'vant';
export default {
    data () {
        return {
            id: '',
            logId:'',
            msgInfo: {},
            htmlContent:''
        }
    },
    methods: {
        //获取信息
        async messageInfo () {
            let res = await api.messageInfo({ id: this.id })
            if (res.code == 200) {
                this.msgInfo = res.data
                this.htmlContent = res.data.htmlContent
                let _this = this
                this.$nextTick(() => {
                    let imgDomList = document.getElementsByTagName('img')
                    for (let index = 0; index < imgDomList.length; index++) {
                        imgDomList[index].onclick = function () {
                            _this.photoPreview(imgDomList[index].src)
                        }
                    }
                })

            }
        },
        async messageRead () {
            let obj = {
                messageId: this.id,
                logId: this.logId,
                sendChannel: '0'
            }
            await api.messageRead(obj)
        },
        photoPreview (url) {
            ImagePreview([url]);
        },

    },
    mounted () {

    },
    created () {
        this.id = this.$route.query.messageId
        this.logId = this.$route.query.logId
        this.messageInfo()
        this.messageRead()
    }
}
</script>

<style scoped lang="scss">
.messageInfo-box {
    .top {
        text-align: left;
        padding: 16px 20px;
        border-bottom: 1px solid #EEEEEE;

        .title {
            font-size: 22px;
            font-family: PingFang SC-Bold, PingFang SC;
            font-weight: bold;
            color: #333333;
            line-height: 31px;
            margin-bottom: 8px;
        }

        .annotation {
            font-size: 14px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 500;
            color: #999999;
            line-height: 20px;
        }

        .text {
            margin-right: 16px;
        }
    }

    .html-content {
        padding: 0 20px;
        padding-top: 16px;
        text-align: left;
        overflow: auto;
    }

    .empty-box {
        height: calc(100vh - 100px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #666666;
        line-height: 20px;
        letter-spacing: 1px;
    }

    .empty {
        width: 205px;
        height: 145px;
    }
}
</style>
<style lang="scss">
.messageInfo-box img {
    max-width: 100%;
}
.html-content {
    video {
        max-width: 100% !important;
    }

    img {
        max-width: 100% !important;
    }
    table{
        border-collapse: collapse;
        border-spacing: 0;
    }
    table td,
    table th {
        border: 1px solid var(--w-e-textarea-border-color);
        line-height: 1.5;
        min-width: 30px;
        padding: 3px 5px;
        text-align: left;
    }
}
</style>
